import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  useTheme,
  Alert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
} from "../../../atoms";
import {
  EMAIL_REGEX,
  MINIMUM_PASSWORD_CHARACTERS,
  ONLY_NUMBERS,
  PHONE_REGEX,
  getChecked,
  getText,
  setText,
} from "../../../../utils/Credentials";
import {
  isLowerCase,
  isNumber,
  isSpecialCharacter,
  isUpperCase,
  PASSWORD_REGEX,
  AUTH_SCREEN_STATES,
} from "../../../../utils/Credentials";
import { AuthRepository, CommonRepositoty } from "../../../../repository";
import _ from "lodash";

export default function SignUpForm({ onPassResPonse = () => {} }) {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [errorPassword, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(
    "Password is required"
  );

  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const [fName, setFName] = useState("");
  const [isFNameValid, setIsFNameValid] = useState(false);
  const [fNameError, setFNameError] = useState(false);

  const [lName, setLName] = useState("");
  const [isLNameValid, setIsLNameValid] = useState(false);
  const [lNameError, setLNameError] = useState(false);

  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [errorPhone, setPhoneError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

  const [addressOne, setAddressOne] = useState("");
  const [isAddressOneValid, setIsAddressOneValid] = useState(false);
  const [addressOneError, setAddressOneError] = useState(false);

  const [addressTwo, setAddressTwo] = useState("");
  const [isAddressTwoValid, setIsAddressTwoValid] = useState(false);
  const [addressTwoError, setAddressTwoError] = useState(false);

  const [province, setProvince] = useState(0);
  const [isProvinceValid, setIsProvinceValid] = useState(false);
  const [provinceError, setProvinceError] = useState(false);

  const [distric, setDistric] = useState(0);
  const [isDistricValid, setIsDistricValid] = useState(false);
  const [districError, setDistricError] = useState(false);

  const [city, setCity] = useState(0);
  const [isCityValid, setIsCityValid] = useState(false);
  const [cityError, setCityError] = useState(false);

  const [zip, setZip] = useState("");
  const [isZipValid, setIsZipValid] = useState(false);
  const [zipError, setZipError] = useState(false);

  const [provinceList, setProvinceList] = useState([]);
  const [districList, setDistricList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [isError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState("");

  const [checked, setChecked] = useState(true);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    onCallProvince();
  }, []);

  const onCallProvince = async () => {
    const data = await CommonRepositoty.onGetProvinceList();
    if (data?.status_code == 1) {
      setError(false);
      setProvinceList(_.get(data, "data", []));
    } else {
      setError(true);
    }
    setErrorMessage(_.get(data, "message", ""));
  };

  const onCallDistric = async (provinceId) => {
    const data = await CommonRepositoty.onGetDistricList(provinceId);

    if (data?.status_code == 1) {
      setError(false);
      setDistricList(_.get(data, "data", []));
    } else {
      setError(true);
    }
    setErrorMessage(_.get(data, "message", ""));
  };

  const onCallCity = async (districId) => {
    const data = await CommonRepositoty.onGetCityList(districId);
    if (data?.status_code == 1) {
      setError(false);
      setCityList(_.get(data, "data", []));
    } else {
      setError(true);
    }
    setErrorMessage(_.get(data, "message", ""));
  };

  const onEmailChange = (e) => {
    const text = getText(e);
    let isValid = false;
    const reg = EMAIL_REGEX;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    setEmail(text);
    setIsEmailValid(isValid);
    setEmailError(false);
  };

  const onChangeFirstName = (e) => {
    const text = getText(e);
    setFName(text);
    setFNameError(false);
    setIsFNameValid(text?.length > 0);
  };

  const onChangeLastName = (e) => {
    const text = getText(e);
    setLName(text);
    setLNameError(false);
    setIsLNameValid(text?.length > 0);
  };

  // const onChangePhone = (e) => {
  //   const text = getText(e);
  //   const reg = PHONE_REGEX;
  //   let isValid = false;
  //   if (text.trim().length > 3) {
  //     isValid = reg.test(text.trim());
  //   }
  //   setPhone(text);
  //   setPhoneError(false);
  //   setIsPhoneValid(text?.length > 0);
  // };

  const onChangePhone = (e) => {
    const text = getText(e);
    const reg = PHONE_REGEX;
    let isValid = false;
    if (text.trim().length <= 11) {
      isValid = reg.test(text.trim());
    }
    setPhone(text);
    setPhoneError(isValid ? false : true);
    setIsPhoneValid(isValid)
  }
  const onChangeFirstAddress = (e) => {
    const text = getText(e);
    setAddressOne(text);
    setAddressOneError(false);
    setIsAddressOneValid(text?.length > 0);
  };

  const onChangeLastAddress = (e) => {
    const text = getText(e);
    setAddressTwo(text);
    setAddressTwoError(false);
    setIsAddressTwoValid(text?.length > 0);
  };

  const onChangeDistric = (e) => {
    onChangeCity(setText(0));
    setCityList([]);
    const text = getText(e);
    setDistric(text);
    setDistricError(false);
    setIsDistricValid(text > 0);
    onCallCity(text);
  };

  const onChangeCity = (e) => {
    const text = getText(e);
    setCity(text);
    setCityError(false);
    setIsCityValid(text > 0);
  };

  const onChangeProvince = (e) => {
    setDistricList([]);
    onChangeDistric(setText(0));
    const text = getText(e);
    setProvince(text);
    setProvinceError(false);
    setIsProvinceValid(true);
    onCallDistric(text);
  };

  const onChangeZip = (e) => {
    const text = getText(e);
    const reg = ONLY_NUMBERS;
    let isValid = reg.test(text.trim());
    setZip(text);
    setZipError(isValid ? false : true);
    setIsZipValid(isValid);
  };

  const handleChange = (e) => {
    const text = getChecked(e);
    setChecked(text);
  };

  const onSignUp = async () => {
    setResError(false);
    if (
      isEmailValid &&
      isPasswordValid &&
      isFNameValid &&
      isLNameValid &&
      isCityValid &&
      isDistricValid &&
      isPhoneValid &&
      isAddressOneValid &&
      isZipValid &&
      checked
    ) {
      let data = {
        first_name: fName,
        last_name: lName,
        email: email,
        mobile: phone,
        password: password,
        confirm_password: password,
        address_line_one: addressOne,

        country_id: "1",
        province_id: province,
        district_id: distric,
        city_id: city,
        zip_code: zip,
      };
      if (isAddressTwoValid) {
        data = { ...data, address_line_two: addressTwo };
      }
      handleOpen();
      const response = await AuthRepository.onSignUp(data);
      if (response) {
        if (response?.status_code == 1) {
          localStorage.setItem("phone", phone);
          onPassResPonse(data);
        } else {
          setResError(true);
          setErrorResMessage(_.get(response, "message", ""));
        }
        handleClose();
      }
    } else {
      if (!isZipValid) {
        setZipError(true);
      }
      if (!isAddressTwoValid) {
        setAddressTwoError(true);
      }
      if (!isAddressOneValid) {
        setAddressOneError(true);
      }
      if (!isPhoneValid) {
        setPhoneError(true);
      }
      if (!isProvinceValid) {
        setProvinceError(true);
      }
      if (!isDistricValid) {
        setDistricError(true);
      }
      if (!isCityValid) {
        setCityError(true);
      }
      if (!isLNameValid) {
        setLNameError(true);
      }
      if (!isFNameValid) {
        setFNameError(true);
      }
      if (!isPasswordValid) {
        setPasswordError(true);
      }
      if (!isEmailValid) {
        setEmailError(true);
      }
      if (!checked) {
        setError(true);
        setErrorMessage("Please agree to the terms and conditions");
      }
    }
  };

  const onPasswordChange = (e) => {
    const text = getText(e);
    // let isValid = text.trim().length > PASSWORD_MINIMUM_CHARACTERS;
    let isMoreThanEight = false,
      isASpecialCharacter = false,
      isANumber = false,
      isCapitalLetter = false,
      isSimpleLetter = false;

    if (text?.length > 0) {
      isANumber = isNumber(text);
      isASpecialCharacter = isSpecialCharacter(text);
      isCapitalLetter = isUpperCase(text);
      isSimpleLetter = isLowerCase(text);
    }

    let message = "Password should have";
    if (!isANumber) {
      message = message + " a number";
    }
    if (!isASpecialCharacter) {
      message = message + ", a special characters";
    }
    if (!isCapitalLetter) {
      message = message + ", a uppercase letter";
    }
    if (!isSimpleLetter) {
      message = message + ", a lowercase letter";
    }

    let isValid = false;

    if (text.trim().length > MINIMUM_PASSWORD_CHARACTERS) {
      isMoreThanEight = true;
      // isValid = true
      isValid = PASSWORD_REGEX.test(text.trim());
    } else {
      message = message + ", a minimum 8 characters";
    }
    setPassword(text);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setPasswordErrorMessage(message);
    setIsPasswordValid(isValid);
    let isConfirmeValid = false;
    if (new String(text).valueOf() === new String(confirmPassword).valueOf()) {
      isConfirmeValid = true;
    }
    setIsConfirmPasswordValid(isConfirmeValid);
  };

  const onConfirmPassword = (e) => {
    const text = getText(e);
    setConfirmPassword(text);
    setConfirmPasswordError(false);

    let isValid = false;
    if (new String(text).valueOf() === new String(password).valueOf()) {
      isValid = true;
    }
    setIsConfirmPasswordValid(isValid);
  };

  return (
    <Grid container flexDirection={"column"} rowSpacing={2}>
      <Grid item>
        <Typography
          fontFamily={"Source sans pro"}
          fontWeight={600}
          color={useTheme().palette.primary.main}
          variant="h5"
        >
          Sign up on Presto Worldwide
        </Typography>
      </Grid>
      {isResError && (
        <Grid item>
          <Alert severity="error">{errorResMessage}.</Alert>
        </Grid>
      )}
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputComponent
              isError={fNameError}
              error={"First Name is required"}
              onChange={onChangeFirstName}
              label="First Name"
              type={"text"}
            />
          </Grid>
          <Grid item xs={6}>
            <InputComponent
              isError={lNameError}
              error={"Last Name is required"}
              onChange={onChangeLastName}
              label="Last Name"
              type={"text"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <InputComponent
          isError={emailError}
          error={email?.length > 3 ? "Email is not valid" : "Email is required"}
          onChange={onEmailChange}
          label="Email Address"
          type={"text"}
        />
      </Grid>
      <Grid item>
        <InputComponent
          isError={errorPassword}
          error={
            password.length > MINIMUM_PASSWORD_CHARACTERS
              ? `Password should have minimum ${MINIMUM_PASSWORD_CHARACTERS}`
              : "Password is required"
          }
          isPassword
          onChange={onPasswordChange}
          label="Password"
          type={"password"}
        />
      </Grid>
      <Grid item>
        <InputComponent
          isError={confirmPasswordError}
          error={
            confirmPassword?.length == 0
              ? "Confirmed password is required"
              : "Confirmed password and password are not mtched"
          }
          onChange={onConfirmPassword}
          isPassword
          label="Confirm Password"
          type={"password"}
          value={confirmPassword}
        />
      </Grid>
      <Grid item>
        <InputComponent
          isError={errorPhone}
          error={
            phone?.length > 0 ? "Mobile number is not valid" : "Mobile number is required"
          }
          onChange={onChangePhone}
          label="Mobile Number"
          type={"text"}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputComponent
              isError={addressOneError}
              error={"Street1 is required"}
              onChange={onChangeFirstAddress}
              label="Address 01"
              type={"text"}
            />
          </Grid>
          <Grid item xs={6}>
            <InputComponent
              onChange={onChangeLastAddress}
              label="Address 02"
              type={"text"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DropdownComponent
              isError={provinceError}
              disabled={false}
              dropdownList={provinceList}
              onChange={onChangeProvince}
              error={"Province is required"}
              label="Province"
            />
          </Grid>
          <Grid item xs={6}>
            <DropdownComponent
              isError={districError}
              dropdownList={districList}
              disabled={!isProvinceValid}
              onChange={onChangeDistric}
              error={"District is required"}
              label="District"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DropdownComponent
              isError={cityError}
              dropdownList={cityList}
              disabled={!isDistricValid}
              onChange={onChangeCity}
              error={"City is required"}
              label="City"
            />
          </Grid>
          <Grid item xs={6}>
            <InputComponent
              isError={zipError}
              error={"Postal Code is Invalid"}
              onChange={onChangeZip}
              label="ZIP"
              type={"text"}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container alignItems={"center"}>
          <Grid item>
            <CheckboxComponent
              checked={checked}
              inputProps={{ "aria-label": "controlled" }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              sx={{
                color: useTheme().palette.grey[500],
                fontFamily: "Source sans pro",
                fontWeight: 600,
              }}
            >
              I agree to the{" "}
              <a href="/terms-and-conditions" target="_blank" style={{textDecoration:'none'}}> <span className="hightlighted-text">Terms Of Use  </span></a> and{" "}
              <a href="/privacy-policy" target="_blank" style={{textDecoration:'none'}}> <span className="hightlighted-text">Privacy Policy</span></a> .
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ButtonComponent
          onClick={onSignUp}
          variant="contained"
          name={"Register"}
        />
      </Grid>
      {isError && (
        <Grid item>
          <Alert severity="error">{errorMessage}.</Alert>
        </Grid>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}
