import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
export const TermsNConditionsPage = () => {
  return (
    <Container maxWidth={"md"} sx={{ mt: 2, mb: 2 }}>
      <Stack spacing={3}>
        <Box>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
            These Terms and Conditions were last updated on 6th November 2019.
            You are viewing these Terms and Conditions because you are engaging
            the international package consolidation and carriage services and
            other ancillary services (“Services”) on the ‘Presto portal
            (hereinafter the “Portal”) available
            at www.prestoworldwide.com (hereinafter the “Site”) owned, developed
            and operated by PRESTO LANKA (PRIVATE) LIMITED, a company duly
            incorporated under the laws of Sri Lanka, bearing Registration No.
            PV 5408 and having its registered office at No.400, Deans road,
            Colombo 10 (hereinafter the "Owner"). You are referred to below as
            you, your, the "Member," or a "Member."
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={600}
            sx={{ textAlign: "justify" }}
          >
            YOU HEREBY ACCEPT AND ACKNOWLEDGE THAT BY ACCESSING AND USING THE
            SERVICES ON THE PORTAL AVAILABLE ON THE SITE, YOU AGREE TO ALSO BE
            BOUND BY THESE PRESTO WORLDWIDE TERMS AND CONDITIONS IN ADDITION TO
            THE TERMS AND CONDITIONS AND THE PRIVACY POLICY OF THE SITE, THAT
            WILL GOVERN YOUR USAGE OF THE PORTAL AVAILABLE ON THE SITE AND THE
            PURCHASE OF SERVICES LISTED AND OFFERED THEREUNDER.
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
            PRESTO WORLDWIDE allows Sri Lankan consumers to shop for their
            favourite US brands online and ship it to Sri Lanka within 09 days
            or less. There's no setup fee, you receive a tax-free US shipping
            address, free package consolidation and discounted shipping rates.
            Sign up on Presto Worldwide and enjoy shopping in the USA!{" "}
          </Typography>
        </Box>

        <Box>
          <ul
            style={{
              fontFamily: "Source Sans Pro",
              fontWeight: 500,
              color: "GrayText",
              textAlign: "justify"
            }}
          >
            <li>
              These Terms and Conditions constitute a valid and binding contract
              within the meaning of applicable law. This electronic record is
              generated by a computer system and does not require any further
              physical or digital signature to constitute a valid and binding
              contract.
            </li>
            <li>
              The Owner reserves the right, at its discretion, to change,
              modify, or otherwise alter these Terms and Conditions. It is the
              Member's responsibility to review these Terms and Conditions
              periodically as continued use of the Services will constitute the
              Member's acceptance of any revised terms and conditions. All
              changes to these Terms and Conditions will be effective
              immediately upon their posting on the Portal. Each version of
              these Terms and Conditions will be prominently marked with the
              date it was last updated. By continuing to use the Services after
              we change the terms of these Terms and Conditions, you consent to
              those changes. If you disagree with any modifications made to
              these Terms and Conditions, your only recourse is to cease using
              the Services.
            </li>
            <li>
              The Owner prohibits any illegal, unlawful or immoral activity by
              any Member in connection with the use of the Services. Any charges
              incurred, for legal advice or otherwise, by the Owner in
              connection with the enforcement of the law against a Member or a
              potential Member will be the financial responsibility of the
              Member or the potential Member. See the Indemnification section
              below for further details.
            </li>
            <li>
              The Member represents and warrants that he/she is responsible for
              complying with all known applicable export and import related laws
              and regulations in the order and receipt of merchandise and
              packages under the terms of these Terms and Conditions.
            </li>
          </ul>
        </Box>
      </Stack>
    </Container>
  );
};
