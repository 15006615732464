import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
export const PrivacyPolicyPage = () => {
  return (
    <Container maxWidth={"md"} sx={{ mt: 2, mb: 2 }}>
      <Stack spacing={1}>
        <Box>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
            PrestoWorldwide.lk website (the “Site”) is owned and operated by
            Presto Lanka (Private) Limited (PV 5408) (the “Owner”). We value
            your privacy and we commit ourselves to use the data and information
            provided by you in a responsible manner. You hereby accept and
            acknowledge that by accessing and using the Site, you agree to be
            bound by the policies described below.
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            color={"#572682"}
            fontWeight={600}
          >
            The collection of data
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
            Personal data entered by you when registering with the Site, and
            purchasing products listed on the Site will be stored and processed
            to provide services, respond to your future claims if any, improve
            your purchasing experience on the website, and to improve our web
            store. The data will also be used for predictive analysis to suggest
            products which you may be interested in.
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
            We may collect information about your location and your mobile
            devices when you’re downloading and using our mobile app. Data would
            be used to enhance the services based on your location such as
            improved product searches, location-based advertisements and other
            personalized contents which we think may be useful. You can always
            deactivate the access to location in the app if you think it’s not
            useful.
          </Typography>
        </Box>

        <Box>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            color={"#572682"}
            fontWeight={600}
          >
            Information sharing policy
          </Typography>
        </Box>

        <Box>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
            We employ third party service providers to perform certain functions
            on behalf of us, such as order fulfillment, delivery, processing
            payment, providing customer service, providing marketing assistance,
            analyzing data. They have access to information which is limited to
            provide the relevant services. We may share information and analysis
            with suppliers who list their product on the Site, where we think
            that such sharing will be helpful for the sellers to serve you
            better when purchasing products.
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
            sx={{ textAlign: "justify" }}
          >
            The business data will be shared on a commercial basis as an
            analysis of the market with other business partners. However, we do
            not give the names and address in specific.
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
};
