import React from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import _ from "lodash";
import { InputComponent, RadioButtonComponent } from "../../../atoms";

export const PaymentOptions = ({
  data = [],
  radioButton,
  handleChangeRadio = () => { },
}) => {

  return (
    <Card sx={{ boxShadow: 'none' }}>
      <CardContent>
        <Grid container alignItems={"center"}>
          <Grid item xs={8}>
            {_.map(data, (item, index) => {
              return (
                <Box key={index + 1}>
                  <RadioButtonComponent
                    name={"payment"}
                    selectedValue={radioButton}
                    value={_.get(item, "id")}
                    label={_.get(item, "slug")}
                    image={_.get(item, "image")}
                    handleChange={() => handleChangeRadio(_.get(item, "id"))}
                  />
                </Box>
              );
            })}
             
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
