import React from "react";
import {
  ActionRequiredExpandable,
  PackageExpandable,
  ReadyToSendExbandable,
  TransactionExpandable,
} from "../../organisms";
import { TableCell, TableRow, useMediaQuery, Box } from "@mui/material";

export const ExpandableRowEntry = ({ rowData, rowMeta, title , onHandleBackDrop=()=>{}, onLoadDataAgain=()=>{}}) => {
  const colSpan = rowData.length + 1;
  const isMobile = useMediaQuery("(max-width:768px)"); 

  return (
    <TableRow>
    <TableCell colSpan={title == "Action Required" ? 5 : 7}>
      {rowData[0] == "Ready To Send" ? <ReadyToSendExbandable onLoadDataAgain={onLoadDataAgain} onHandleBackDrop={onHandleBackDrop} data={rowData} readytoSend={true}/> : null}
      {rowData[0] == "Action Required" && title != "View All" ? <ActionRequiredExpandable onLoadDataAgain={onLoadDataAgain} onHandleBackDrop={onHandleBackDrop} data={rowData} /> : null}
      {rowData[0] == "transactions" ? <TransactionExpandable/> : null}
      {rowData[0] == "Prepared Package" ? <PackageExpandable onLoadDataAgain={onLoadDataAgain} onHandleBackDrop={onHandleBackDrop} data={rowData}/> : null}
      </TableCell>
    </TableRow>
  );
};
