import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
export const FaqPage = () => {
  return (
    <Container maxWidth={"md"} sx={{ mt: 2, mb: 2 }}>
      <Stack>
        <Box>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            color={"#572682"}
            fontWeight={600}
          >
            Registration Information
          </Typography>
        </Box>
        <Box>
          <ol
            type="a"
            style={{
              fontFamily: "Source Sans Pro",
              fontWeight: 500,
              color: "GrayText",
            }}
          >
            <li style={{ fontWeight: 600 }}>
              How can I register for this service?
            </li>
            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"GrayText"}
              fontWeight={500}
            >
              If you already have a Presto.lk account, then you can simply log in
              using your existing account. or you can register Prester Worldwide
              by providing the relevant details.
            </Typography>
            <li style={{ fontWeight: 600 }}>
              Can I start using Presto Worldwide services immediately after I
              sign up?
            </li>
            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"GrayText"}
              fontWeight={500}
            >
              Yes. Only you receive your account confirmation, you are ready to
              start shopping!
            </Typography>
            <li style={{ fontWeight: 600 }}>
              I understand you will give me a US address. Is it a P.O box
              address?
            </li>
            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"GrayText"}
              fontWeight={500}
            >
              No. Presto Worldwide provides you with a complete street address
              and a unique suite number, Not a P.O box address. All the US
              shipping companies will be able to deliver to your Presto
              Worldwide addresses.
            </Typography>
          </ol>
        </Box>
        <Box>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            color={"#572682"}
            fontWeight={600}
          >
            Account Information
          </Typography>
        </Box>
        <Box>
          <ol
            type="a"
            style={{
              fontFamily: "Source Sans Pro",
              fontWeight: 500,
              color: "GrayText",
            }}
          >
            <li style={{ fontWeight: 600 }}>
              How do I use my Presto Worldwide address?
            </li>
            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"GrayText"}
              fontWeight={500}
            >
              Enter your Presto Worldwide account as the “Shipping Address”
              given below, whenever you buy.checkout from online US stores.
            </Typography>

            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"darkblue"}
              fontWeight={600}
            >
              Name: Your Name <br />
              Address (Line1): xxxx Express Lane, <br />
              Address(Line2): Suite xxx xxx (Enter your suite number here),
              <br /> City: Sarasota <br /> State: Florida or FL Zip Code/postal
              Code: 34249
            </Typography>

            <Typography
              variant="subtitle1"
              fontFamily={"Source Sans Pro"}
              color={"GrayText"}
              fontWeight={500}
            >
              You can find your presto worldwide address when you sign into your
              account, on the upper right hand corner of your screen.
            </Typography>
          </ol>
        </Box>

        <Box>
          <Typography
            variant="h6"
            fontFamily={"Source Sans Pro"}
            color={"#572682"}
            fontWeight={600}
          >
            Declared values and Receipts
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            fontFamily={"Source Sans Pro"}
            color={"GrayText"}
            fontWeight={500}
          >
            Customers require the purchase value of all imported products. If
            your package arrives without an invoice to our warehouse, Presto
            Worldwide will require the customer to update the details by uploading
            your merchant Invoice/Receipt into the system, under the action
            required tab.{" "}
          </Typography>
        </Box>
      </Stack>
    </Container>
  );
};
