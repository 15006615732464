import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { ButtonComponent, PopupComponent } from "../../../atoms";
import { DashBoardRepository } from "../../../../repository";
import _ from "lodash";
import { PaymentOptions } from "../PaymentOptions/PaymentOptions";

export const RequestPhotoOptions = ({
  handleRequestNow = () => {},
  handleGetNow = () => {},
}) => {
  const [basicPhotoRequest, setBasicPhotoRequest] = useState([]);
  const [AdvancedPhotoRequest, setAdvancePhotoRequest] = useState([]);
  const [paymentPopUp, setPaymentPopUp] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [radioButton, setRadioButton] = useState("");
  const [selectedValue, setSelectedValue] = useState("0");
  const [comment, setComment] = useState("");
  const [selectedService, setSelectedService] = useState("");

  const [resError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState("");

  useEffect(() => {
    onGetSpecialRequestChargers();
    getActivePaymentGateways();
  }, []);

  const onGetSpecialRequestChargers = async () => {
    setResError(false);
    const response = await DashBoardRepository.onGetSpecialRequestChargers();
    if (response) {
      if (response?.status_code == 1) {
        setBasicPhotoRequest(_.get(response, "data.basicPhotoRequest[0]", []));
        setAdvancePhotoRequest(_.get(response, "data.advancePhotoRequest[0]", []))
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, "message", ""));
      }
      // handleClose();
    }
  };

  const openPaymentPopup =(value,service)=>{
    setSelectedValue(value)
    setSelectedService(service);
    setPaymentPopUp(true);
  }

  const getActivePaymentGateways = async () => {
    const response = await DashBoardRepository.onGetActivePaymentGateways();
      if (response?.status_code == 1) {
        setPaymentMethods(_.get(response, "data", []));
      } else {
        setResError(true);
        setErrorResMessage(_.get(response, 'message', ''));
      }
  };

  const handleChangeRadio = (value) => {
    setRadioButton(value);
  };

  const onChanageComment = (value) => {
    setComment(_.get(value, "target.value"));
  };
  const closePaymentPopup = () => setPaymentPopUp(false);

  return (<>
    <Card sx={{ boxShadow: "none" }}>
      <CardContent>
        <Stack spacing={2}>
          <Box>
            <Stack spacing={1}>
              <Typography
                variant="h6"
                color={"#572682"}
                fontFamily={"Source Sans Pro"}
                fontWeight={600}
              >
                Basic Photo - {`$${basicPhotoRequest?.amount}`}
              </Typography>

              <Typography
                variant="subtitle1"
                color={"GrayText"}
                fontFamily={"Source Sans Pro"}
                fontWeight={500}
              >
                View low resolution photos taken of your package.
              </Typography>

              <Box>
                <ButtonComponent
                  variant="contained"
                  onClick={() => openPaymentPopup(`${basicPhotoRequest?.amount}`,"O-Picture")}
                  name={"Get Now"}
                />
              </Box>
            </Stack>
          </Box>

          <Box>
            <Stack spacing={1}>
              <Typography
                variant="h6"
                color={"#572682"}
                fontFamily={"Source Sans Pro"}
                fontWeight={600}
              >
                Advanced Photo - {`$${AdvancedPhotoRequest?.amount}`}
              </Typography>

              <Typography
                variant="subtitle1"
                color={"GrayText"}
                fontFamily={"Source Sans Pro"}
                fontWeight={500}
              >
                Order upto 20 high resolution photos of your package contents to
                confirm color , condition and item details.
              </Typography>

              <Box>
                <ButtonComponent
                  variant="contained"
                  onClick={() => openPaymentPopup(`${AdvancedPhotoRequest?.amount}`,"O-AdvancedPicture")}
                  name={"Request Now"}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </Card>
    <PopupComponent
        open={paymentPopUp}
        actionButtonName={"Pay"}
        handleAction={() => {
          handleRequestNow(selectedValue, radioButton, selectedService);
          closePaymentPopup();
        }}
        handleClose={() => setPaymentPopUp(false)}
        maxWidth={"sm"}
        title={`Payment options - $${selectedValue}`}
        showBottomBtns={true}
        showSecondaryBtn={false}
        content={<PaymentOptions data={paymentMethods}  
        radioButton={radioButton}
        handleChangeRadio={(value) => handleChangeRadio(value)}
        />}
    />
    </>);
};
