import React, { useState, useEffect, useRef } from "react";
import { Button, Divider, Grid, Typography, Alert, Backdrop, CircularProgress } from "@mui/material";
import { ButtonComponent, InputComponent } from "../../../atoms";
import { theme } from "../../../../style/Theme/Theme";
import { IMAGES } from "../../../../assets";
import { getText, setText, EMAIL_REGEX, MINIMUM_PASSWORD_CHARACTERS, PHONE_REGEX } from "../../../../utils/Credentials";
import { AuthRepository } from "../../../../repository";
import _ from "lodash"
import { useNavigate } from "react-router-dom";

export default function SignInForm({ onClickForgotPw = () => { } }) {
  // const [email, setEmail] = useState('');
  // const [isEmailValid, setIsEmailValid] = useState(false);
  // const [emailError, setEmailError] = useState(false);
  // const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [errorPhone, setPhoneError] = useState(false);
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [errorPassword, setPasswordError] = useState(false);
  const [isPasswordSecure, setIsPasswordSecure] = useState(true);

  const [isResError, setResError] = useState(false);
  const [errorResMessage, setErrorResMessage] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };


  const onChangePhone = (e) => {
    const text = getText(e);
    const reg = PHONE_REGEX;
    let isValid = false;
    if (text.trim().length > 3) {
      isValid = reg.test(text.trim());
    }
    setPhone(text);
    setPhoneError(false);
    setIsPhoneValid(isValid)
  }

  // const onEmailChange = (e) => {
  //   const text = getText(e);
  //   let isValid = false;
  //   const reg = EMAIL_REGEX;
  //   if (text.trim().length > 3) {
  //     isValid = reg.test(text.trim());
  //   }
  //   setEmail(text);
  //   setIsEmailValid(isValid);
  //   setEmailError(false);
  // };

  const onPasswordChange = (e) => {
    const text = getText(e);
    let isValid = false;
    isValid = text.trim().length > MINIMUM_PASSWORD_CHARACTERS;
    setPassword(text);
    setIsPasswordValid(isValid);
    setPasswordError(false);
  };


  const onLogin = async () => {
    setResError(false)
    if (isPhoneValid && isPasswordValid) {
      const response = await AuthRepository.onLogin({ user_name: phone, password });
      handleOpen()
      if (response) {
        if (response?.status_code == 1) {
          localStorage.setItem('token', _.get(response, 'data.token', ''));
          localStorage.setItem('user', JSON.stringify(_.get(response, 'data.user', '')));
          navigate("/my-suite");
        } else {
          setResError(true);
          setErrorResMessage(_.get(response, 'message', ''));
        }
        handleClose()
      }
    } else {
      if (!isPhoneValid) {

        setPhoneError(true);
      }
      if (!isPasswordValid) {
        setPasswordError(true);
      }
    }
  };

  return (
    <Grid container flexDirection={"column"} spacing={2}>
      {isResError && <Grid item>
        <Alert severity="error">{errorResMessage}.</Alert>
      </Grid>}
      <Grid item xl={10}>
        <Typography variant="h5" className="auth-header">
          Login with Your Presto Worldwide account.
        </Typography>
      </Grid>
      <Grid item>
        <Grid container flexDirection={"column"} spacing={3}>
          <Grid item>
            <InputComponent
              // isError={emailError}
              // error={email?.length > 3 ? 'Email is not valid' : 'Email is required'}
              // value={email} onChange={onEmailChange} size="medium" label="Email" type={"email"} 
              isError={errorPhone}
              error={phone?.length > 0 ? 'Mobile number is not valid' : 'Mobile number is required'}
              onChange={onChangePhone}
              label='Mobile Number'
              type={'text'}
              value={phone}
            />
          </Grid>
          <Grid item>
            <InputComponent
              isPassword
              isError={errorPassword}
              error={password.length > MINIMUM_PASSWORD_CHARACTERS ? `Password should have minimum ${MINIMUM_PASSWORD_CHARACTERS}` : 'Password is required'}
              value={password} onChange={onPasswordChange} size="medium" label="Password" type={"password"} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          sx={{ cursor: "pointer", width: "fit-content" }}
          color={theme.status.smallTextColor}
          onClick={onClickForgotPw}
        >
          Forgot Password ?
        </Typography>
      </Grid>
      <Grid item>
        <ButtonComponent onClick={onLogin} size={"large"} variant="contained" name={"Login"} />
      </Grid>
      {/* 
      <Grid item>
      <Divider textAlign="left"><Typography color={theme.status.smallTextColor}>or</Typography></Divider>
      </Grid> */}

      {/* <Grid item>
        <Button
          variant="contained"
          sx={{ width: { lg: "80%", md: "80%", sm: "80%", xs: "100%" } }}
        >
          <Grid container alignItems={"center"} justifyContent={"space-evenly"}>
            <Grid item xs={2} pt={1}>
              <img src={IMAGES?.LOGO?.PRESTO_LOGO_MINI} alt="logomini" />
            </Grid>
            <Divider orientation="vertical" color={"#fff"} flexItem />
            <Grid item xs={7}>
              <Typography
                variant="button"
                fontWeight={600}
                textTransform={"capitalize"}
                color="#fff"
              >
                Login with Presto Account
              </Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid> */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
}
