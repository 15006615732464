import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { InputLabel, OutlinedInput, InputAdornment, IconButton, FormControl, FormHelperText } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function InputComponent({
  label = "Password",
  error,
  isError,
  value,
  type,
  minRows,
  maxRows,
  multiline = false,
  isPassword = false,
  onChange = () => { },
  isDisabled = false,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  if (isPassword) {
    return (
      <FormControl fullWidth variant="outlined">
        <InputLabel error={isError} size="small" htmlFor={`outlined-adornment-${label}`}>{label}</InputLabel>
        <OutlinedInput
          value={value}
          onChange={onChange}
          error={isError}
          fullWidth
          size={"small"}
          id={`outlined-adornment-${label}`}
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label={label}
        />
        {isError ? (<FormHelperText error={isError} id="outlined-weight-helper-text">{error}</FormHelperText>) : null}
      </FormControl>
    );
  }
  return (

    <FormControl fullWidth variant="outlined">
      <TextField
        disabled={isDisabled}
        id="outlined-basic"
        fullWidth
        size="small"
        label={label}
        type={type}
        error={isError}
        onChange={onChange}
        value={value}
        variant="outlined"
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        autoComplete="off"
      />
      {isError ? <FormHelperText error={isError} id="outlined-weight-helper-text">{error}</FormHelperText> : null}
    </FormControl>
  );
}
